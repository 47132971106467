.primary-block{
    background-color: #ff6e19;
    background-image: url(https://academist.qodeinteractive.com/wp-content/uploads/2018/07/h-instructor-img-3.jpg);
    min-height: 400px;
    background-size: cover;
}

.block-content-padding{
    padding: 115px 17% 143px 20% !important;
}

.secondary-block{
    background-image: url(https://academist.qodeinteractive.com/wp-content/uploads/2018/07/pattern.jpg);
    min-height: 400px;
}

.mission-bg{
    background-image: url(https://academist.qodeinteractive.com/wp-content/uploads/2018/06/main-home-img-2.jpg);
}

.bg-red .block-content-padding h2{
    color: white;
}

.bg-red .block-content-padding p{
    color: white;
}

.secondary-block .block-content-padding h2, p{
    color: black;
}

.block-content-padding p{
    font-family: PoppinsRegular;
}

@media screen and (max-width: 600px){
    .block-content-padding{
        padding: 40px 10% 48px 12% !important;
    }
    .mission-bg{
        min-height: 300px; 
    }

    .secondary-block{
        height: inherit!important;
        min-height: 0!important;
    }

    .vision-block{
        flex-direction: column-reverse!important;
    }
    .row-reverse{
        flex-direction: column-reverse!important;
    }
}