.tour-content{
    padding-right: 120px;
    margin-top:30px;
}

.tour-content-list{
    padding-left: 20px; 
}

.tour-content p{
    line-height: 1.8rem;
}

.para-class{
    font-family: PoppinsRegular;
    color: black!important;
}

@media screen and (max-width: 600px){
    .tour-content{
        padding-right: 0!important;
    }

   
}