.slide img{
    width: 320px; height:auto;
}

.slide{
    min-height: 420px;
}

.swiper-button-prev{
    left: -40px;
}

.swiper-button-next{
    right: -35px;
}