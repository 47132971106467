.grid-container-4{
    grid-template-columns: auto auto auto auto;
    grid-row-gap: 0px;
}
@import "~react-image-gallery/styles/css/image-gallery.css";

.gallery-item{
    padding: 0px 5px 0px 0px; 
}

.gallery-item img{
   height: 360px;
   width: 100%;
   object-fit:cover;
}

.image-gallery-icon{
    filter:none!important;
}

.image-gallery-icon:hover{
    color: #ff6e19;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
    height: 90px!important;
}



.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus, .image-gallery-thumbnail:hover{
    border:4px solid #ff194b71!important;
}

@media screen and (max-width: 600px){
    .grid-container-4{
        grid-template-columns: auto auto auto;
    }
    .gallery-item:nth-child(3n+0){
        padding: 0!important;
    }   
}