.header-content{
  position: relative;
    width: 100%;
    vertical-align: middle;
    height: 240px;
    background-color: #f6f6f6;
    background-position: center 0;
    background-repeat: no-repeat;
    flex: 1; 
    justify-content: center;
}

.no-padding-top{
  padding-top: 0px; 
}

.plain-bg{
  background-image:url(https://academist.qodeinteractive.com/wp-content/uploads/2018/07/Title-area-img.jpg);
}


@media screen and (max-width: 768px){
  .header-content .container{
    padding-left: 10px; 
  }
}