
.title-para{
    text-align: left;
    font-family: PoppinsRegular;
}

.quote{
    font-family: PoppinsBoldItalic;
    font-size: 24px; 
    margin: 20px 0;
    line-height: 36px;
}

.seperator {
    border-color: #ff6e19;
    border-style: solid;
    width: 32px;
    border-bottom-width: 1px;
    margin-top: 30px;
    margin-bottom: 30px
}

.sign{
    width: 240px;
    margin-top: 10px;
}

.desk-image{
    width: 100%; 
    padding: 0px 20px;
    height: 100%; 
    object-fit: cover;
}

@media screen and (max-width: 620px){
    .desk-image{
        padding: 0 10px!important;
        width: 95%!important;
    }
    .desk-content{
        padding: 0 10px!important;
    }
    .footer-content{
        padding: 0 20px!important;
    }
}