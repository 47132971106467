.subject-header{
    text-transform: uppercase;    
}

.subject-content{
    font-family: PoppinsRegular;
    font-size: 14px;
}

.subject-content b{
    font-family: PoppinsSemiBold;
}

.break-line{
    border-bottom: 2px dotted #ccc;
    margin-top: 30px; 
    margin-bottom: 30px;
}