.slide img{
    width: 320px; height:auto;
}

.swiper-button-prev{
    left: -40px;
}

.swiper-button-next{
    right: -35px;
}

.teacher-slide{
    min-height: 240px!important;
}

@media screen and (max-width: 660px){
    .teacher-slide{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 320px;
    }
    .teacher-slide div{
        text-align: center;
    }
    .swiper-button-next{
        right: -5px!important;
    }
    .swiper-button-prev{
        left: -5px!important;
    }
    .swiper-button-prev, .swiper-button-next{
        top: 70%;
    }
}