.transparent{
    background: transparent;
}

.img-flex .img-content{
    flex: 0.7;
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
    width:90%;
    margin: auto; 
}

.modal-detail{
    padding-right: 0px; 
}

.img-flex .img-description{
    flex: 0.3;
}

.img-holder{
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
    width:90%;
    margin: auto; 
}

.facility-btn{
    top: 55%;
}

.img-description{
    padding: 20px; 
    height: 100%;
    overflow-y: scroll;
    background: white;
    max-width: 320px;
    transform: translate(380px); 
    transition: transform 0.5s;
}

.slide img{
    min-height: 360px;
    width:100%;
    object-fit: cover;
}

.is-open-bar.img-description{
    transform: translate(0);
}

.img-holder img{
    max-height: 620px;
    width: inherit; 
    object-fit: cover;
}

.close{
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    left: 10px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.close div svg{
    width: 30px; height: 30px; 
}

.close svg path{
    stroke: white!important;
}

.pnlm-container{
    background: none !important;
}

@media screen and (max-width: 660px){
    .img-flex .img-content{
        flex: 0.3!important;
    }
    .img-flex .img-content img{
        width: 100% !important;
    }
    .img-description{
        flex: 0.5!important;
        max-width: 100%!important;
    }

}