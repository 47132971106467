.footer-container {
  margin-top: 2px;
  background-color: #252525;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow:  hidden;
}

.footer-content {
  font-family: PoppinsLight;
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;
  color: #ffffff;
}

.footer-menu-text {
  color: #ffffff;
  font-size: 17px;
  font-weight: 600;
  z-index: 20;
  font-family: PoppinsRegular;
  padding: 20px;
}

.footer-menu-list-wrapper {
  display: inline-flex;
  list-style-type: none;
}

.logo-footer {
  height: 120px;
  width: 420px;
  position: relative;
  flex: 1;
  align-items: center;
}

.logo-footer .logo-holder > .caption {
  color: white !important;
}

.footer-content-view{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.social-links{
  font-size: 20px;
  margin-left: 12px;
  color: white;
}

@media screen and (max-width: 768px) {
    .logo-footer{
        margin-left: 20%;
    }
}