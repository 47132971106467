.tab-list{
    border-bottom: 2px dotted #e0e0e0; 
    list-style: none;
    position: relative;
    width: 100%;
    vertical-align: middle;
    padding-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    white-space: nowrap;
    align-items: center;

}

.tab-list li{
    display: inline-block;
    padding:13px 20px;
    padding-left:0px;
    line-height: 12px; 
    font-family: PoppinsRegular;
    font-size: 17px;
    line-height: 25px;
    font-weight: 600;
    color: #252525;
    overflow: hidden;
    -webkit-transition: padding .2s ease-in-out;
    -moz-transition: padding .2s ease-in-out;
    transition: padding .2s ease-in-out;
}

.tab-list a{
    font-family: PoppinsRegular;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 2s;
}

.link-icon{
  width:0px;  
  transition: width 2s;
}

.tab-list  a:hover  .link-icon{
    width:inherit!important;
}

.tab-list a.active .link-icon{
    width:inherit!important;
}

.tab-list a  .link-icon{
    margin-right: 5px;
}

@media screen and (max-width: 600px){
    .tab-list{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}