.pdf-preview-container {
  position: relative;
  flex: none;
  font-size: 14.2px;
  line-height: 19px;
  color: #0c0c0c;
  margin-top: 10px;
  max-height: 480px !important;
  border-radius: 12px;
}

.pdf-container-wrapper {
  background-color: #fff;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
  border-radius: 12px;
}

.grid-row-gap-sm {
  grid-row-gap: 5px;
  margin-bottom: 10px;
}

.pdf-image-container {
  background: #ffcda236;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.react-pdf__Document {
  max-height: 250px;
  overflow: hidden;
}

.react-pdf__Page {
  padding-top: 25px;
}

.react-pdf__Page__canvas {
  width: 360px !important;
  height: 480px !important;
  object-fit: cover;
  margin: auto;
}

.title-content {
  font-family: PoppinsLight;
  font-size: 18px;
}

.pdf-info {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #f7f7f7;
}

.react-pdf__Page__textContent {
  display: none !important;
}

.pdf-info-content {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.pdf-icon {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAA8CAMAAAD8KXLNAAAAvVBMVEUAAAD3d3fxRkbyRkbyRkb3fn74g4PyRUX3lZXzRUXyRUX/RkbyQED3fn7zRUX2iYn/Rkb4lpb0cHDvQEDzRETzRkbzQEDzQkLzOzv////939/yODj2enr4kJD0UVHzPT38xMT92tr5lZX1ZGT+6+v4g4P1bW35m5v7yMj91dX3fn76sLD1XFz6qan//f36trb+5OT0VVX6rKz7vb3yNDT4iIj/9vb0TEz5o6PtTEzlRUXsYWHiNzfyRETsSEiKDgROAAAAFHRSTlMAH+Z5+80n+JHu0AWTv6w6CX3V/BbNCjoAAAHfSURBVHgB7daDsisxGMDxHNtftLZqu+//WDebOu1p08H1f7D8zRoIoferx4djXb4htYvXy7vrI32NXi4U8v6KjzcbFTcKer48bQplS3dYwyhbwhpGRTpGRZpGoNvzjUT6RkU6RkX6RiJNM7dUhE/2NYkVhDU2lCgIa6DxxNr0eathBPoab5re6xjJ1pkaRu2XmP8GiIzKCSrHAW9NiKhiwKnKsFkukRMOZaWCVnWZA7uGxEaZn6UDApBGYjz3ekDFZtrGoig1FRPwZXnoQoXL6p0qwSTjy9pdxdicG41K1ue8g6kwRiWLOPcHQDzO84aoE7N9M2SMWX1eD11hal0zNMQ8tzQ2c0UU75saBXB9zhMmTJthsyc2J7cTuIRSQQ5sxzRZK9oYN4x4VGXCDMtz14IDxiusntiM0SILQ6oG71umMLKcHTCL+rEL+qZe5iWUwta+hUwYz7btoCAHTCdJEgcTgKUxY84NfPwc1AihABhLM+x2N+c6oICXqWa5RJo8bTQ59x04YgJhYMvIMnnvfGtivxmsTdr0RbmNCca0ZjR7FCtGNnCcAd6aEIELyhLFABXb35qg62k59v9d9d/8MnN5fa75QFeXZ5qvN4SezzTPCKEn8et/p9vD49UT+gEdNgrQ89qG8QAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: contain;
  width: 26px;
  height: 30px;
}

.grid-container-4{
  grid-template-columns: auto auto auto auto;
  display: grid;
}

.doc-icon{
  color: #ff6e19;
  font-size: 24px;
}

.disclosure-link h4{
  color: #ff6e19!important;
  text-decoration: underline;
}

@media screen and (max-width: 660px) {
  .grid-row-gap-sm {
    grid-template-columns: auto!important;
    padding: 0px 5px!important;
  }
  .grid-container-4{
    grid-template-columns: auto auto auto;
 }
  .react-pdf__Page__canvas{
      width: 270px!important;
  }
}
