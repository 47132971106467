
.parallax-content{
    text-align: right;
    z-index: 1;
    position: absolute;
    right: 5%;
    bottom: 10%;
}

.parallax-block{

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

}



.parallax-content h1{
    z-index: 5;
    min-width: 0px;
    max-width: none;
    white-space: nowrap;
    font-size: 80px;
    line-height: 100px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    letter-spacing: 0px;
    font-family: PoppinsBold;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: right;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    min-height: 0px;
    max-height: none;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}

.separator-parallax{
    z-index: 6;
    float:right;
    background-color: rgb(255, 255, 255);
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: inherit;
    line-height: 24px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-weight: 300;
    font-size: 15px;
    white-space: nowrap;
    min-height: 3px;
    min-width: 38px;
    max-height: 3px;
    max-width: 38px;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}

.parallax-description {
    z-index: 7;
    min-width: 505px;
    max-width: 505px;
    white-space: normal;
    font-size: 19px;
    line-height: 30px;
    font-weight: 300;
    color: rgb(255, 255, 255)!important;
    letter-spacing: 0px;
    font-family: PoppinsRegular;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: right;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    min-height: 0px;
    max-height: none;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}

.parallax-description p{
    color: rgb(255, 255, 255)!important;
}

.parallax-secondary-image{
    z-index: 8;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: inherit;
    line-height: 0px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-weight: 300;
    font-size: 15px;
    white-space: nowrap;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}

.parallax-secondary-image img{
    width: 460px;
    height: 86px;
}

.parallax-elements{
    margin-top: 20px; 
    margin-bottom: 10px;
}

@media screen and (max-width: 600px){

    .parallax-secondary-image img{
        width: 320px!important;
        height: 86px;
    }

    .parallax-description{
        min-width: 320px!important; 
        max-width: 320px!important; 
    }
    
    .parallax-content h1{
        font-size: 45px!important; 
    }
}