.profile-description .role{
    font-family: PoppinsRegular;
}

.profile-description{
    display: flex; 
    flex-direction: column;
    justify-content: center;
}

.profile img{
    width: 140px;
}

.profile-description h4{
    font-size: 20px;
}


@media screen and (max-width: 1024px){
    .team-grid{
        display: grid;
        grid-template-columns: auto auto;
        grid-row-gap: 50px;
        padding: 10px
    }
}
