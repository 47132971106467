.seperator {
  border-color: #ff6e19;
  width: 38px;
  border-bottom-width: 1px;
  margin-top: 23px;
  margin-bottom: 9px;
}

.contact-text {
  display: block;
  margin: 14px 0 0;
  font-size: 19px;
  color: #333;
  font-weight: 300;
  font-family: PoppinsLight;
  margin-top: 14px;
}

.contact-form-wrapper {
  margin-top: 30px;
}

.form-input {
  background-color: transparent;
  border: 1px solid #e1e1e1;
  border-radius: 0;
  box-sizing: border-box;
  color: #252525;
  font-family: inherit;
  font-size: 15px;
  font-weight: inherit;
  line-height: calc(50px - (12px * 2) - 2px);
  margin: 0 0 16px;
  outline: 0;
  padding: 12px 16px;
  position: relative;
  width: 100%;
}

.form-input-wrapper {
  margin-left: 30px;
  flex: 1;
}

.form-inner-wrapper {
  display: flex;
  flex-direction: row;
}

.input-message {
  height: 160px;
}

.admission-from-wrapper {
  margin-top: 130px;
}

.admission-form-input {
  background-color: transparent;
  border: 0px;
  border-bottom: 1px solid #e1e1e1;
  border-radius: 0;
  box-sizing: border-box;
  color: #252525;
  font-family: inherit;
  font-size: 15px;
  font-weight: inherit;
  line-height: calc(50px - (12px * 2) - 2px);
  margin: 0 0 16px;
  outline: 0;
  padding: 12px 16px;
  position: relative;
  width: 100%;
  -webkit-appearance: none;
  -webkit-transition: border-color 0.2s ease-in-out;
  -moz-transition: border-color 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out;
}

.form-left {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 546px;
  flex: 1;
  padding-left: 60px;
  padding-right: 100px;
}

.form-right {
  background-color: #ffffff;
  padding-right: 80px;
  flex: 1;
  padding-left: 80px;
  padding-top: 70px;
  padding-bottom: 70px;
}

.clearClass {
  position: absolute;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-left: 0;
  border-right: 20px solid #ffffff;
  left: 48.5%;
}

.admission-form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1092px;
  padding-top: 160px;
}

.admission-button {
  width: 180px;
  margin-top: 40px;
  text-align: "center";
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .form-right {
    padding: 30px;
  }

  .admission-form-container {
    padding-top: 15px !important;
  }

  .form-input-wrapper{
    margin-left:0!important;
  }

  .form-left {
    padding-left: 20px;
    padding-right: 40px;
  }

  .clearClass {
    position: absolute;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-left: 0;
    border-right: 20px solid #ff6f18;
    left: 45.5%;
    top: 38%;
    transform: rotate(-90deg);
  }
}
