.hidden-overflow{
    overflow:hidden;
}

.clear:after, .clear:before {
    content: " ";
    display: table;
}

.clear::after{
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 15px;
    margin-top: -15px;
    border-right-color: #ff6e19;
    z-index: 5;
}

.slider-img img{
    width:100%; 
    height: 100%; 
    transform: translate3d(-150px, 0, 0); 
    opacity: 0;
    object-fit: cover;
}

.swiper-slide-active .slider-img img{
    opacity:1; 
    transform: translate3d(0, 0, 0);
    transition: transform .5s .4s,opacity .6s .5s;
}

.slider-text{
    opacity: 0; 
    transition: 1s .4s;
}

.swiper-slide-active .slider-text{
    opacity: 1!important; 
    transform: translate3d(0, 10px, 0);
}

.testimonial-title {
    margin: 24px 42px 0px 0px;
    color: white;
  }
  
  .testimonial-content {
    background: url(https://academist.qodeinteractive.com/wp-content/plugins/academist-core/assets/img/quote.png)
      center no-repeat;
    position: relative;
    height: 100%;
    vertical-align: middle;
    width: 50%;
    box-sizing: border-box;
    background-size: contain;
    background-position: 100px;
  }

  .swiper-slide-active .testimonial-content{
      background-position: 0px;
      transition: .4s;
  }
  
  .testimonial-content-block {
    padding: 0 3% 0 15.5%;
  }
  
  .testimonial-author {
    font-size: 19px;
    font-weight: 300;
    font-family: PoppinsLight;
    color: white;
  }
  
  .control {
    position: absolute;
    bottom: 30px;
    right: 35%;
    z-index: 11;
  }
  
  .control-icons {
    height: 30px;
    width: 30px;
    stroke-width: 2px;
    fill: white;
    cursor: pointer;
  }


  
