.nav-wrapper{
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.nav-holder{
    position: fixed;
    width: 100%;
    z-index: 11;
    top: 0%;
    background: white;
}

.pos-relative{
    position: relative; 
}

.menu-list-wrapper{
    display: inline-flex;
    list-style-type: none;
}

.menu-item{
    display: inline-block;
    position: relative;
    margin: 0 24px;
}

.menu-item-icon{
    display: inline-block;
    position: relative;
    margin: 0 24px;
    display: none!important;
}

.menu-text{
    color: #252525;
    font-size: 17px;
    font-weight: 600;
    z-index: 20;
    font-family: PoppinsRegular;
}

.menu-text:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    left: 0;
    background-color: #ff6e19;
    transform-origin: bottom left;
    transition: transform 0.4s ease-out;
    top: 100%;
}

.menu-text:hover:after{
    transform: scaleX(1);
    transform-origin: bottom right;
}

.menu-text.active:after{
    transform: scaleX(1);
    transform-origin: bottom right;
}

.menu-item-dropdown{
    display: inline-block;
    position: relative;
    margin: 0 24px;
}

.subnav-wrapper {
    position: absolute;
    list-style: none;
    padding-left: 30px;
    padding-bottom: 20px;
    min-width: 200px;
    max-width: 200px;
    float: none;
    background-color: #ffffff;
    left: -45px;
    top: 100%;
    z-index: 111;
}

.subnav-wrapper {
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transform: translateY(-2em);
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;    
}

.submenu-text {
    font-size: 15px;
    line-height: 23px;
    color: #444;
}

.submenu-text-wrapper {
    margin-top: 30px;
}

.menu-item-dropdown:hover .subnav-wrapper {
    height: auto;
    opacity: 1;
    transform: translateY(0%);
}

.nav-icon {
    font-size: 20px;
    margin-top: 3px;
}

.caption{
    font-size: 24px;
    font-family: 'PoppinsBold';
    margin-left: -20px;
}

.seperator {
    border-color: #ff6e19;
    border-style: solid;
    width: 32px;
    border-bottom-width: 1px;
    margin-top: 30px;
    margin-bottom: 30px
}

.logo-side-nav{
    height: 120px
}


.logo-holder{
    height: 90px;
    width: auto;
    display: flex;
    align-items: center;
}

.logo-holder img{
    height: 100%;
    width:auto;
}

.logo-side-nav .logo-holder{
    top: 30px; 
    left: -34px;
}

.logo-side-nav .logo-holder .caption{
    color: white!important;
}

.side-nav-wrapper {
    position: fixed;
    background-color: #252525; 
    right: 0; 
    top: 0;
    width: 500px;
    height: 100%;
    z-index: 100;
}

.side-nav-inner {
    padding:60px
}

.side-nav-logo{
    margin-top: 20px;
}

.side-nav-text-content {
    color: white;
    font-weight: 300;
    font-size: 15px
}

.side-nav-menu-wrapper{
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-top: 40px;
}

.side-nav-menu-item {
    margin-top: 20px;
}

.side-nav-menu-text {
    color: white;
    font-size: 20px;
    line-height: 1.4em;
    font-weight: 700;
    font-family: PoppinsBold;
}

.side-nav-menu-text:hover{
    color: #ff6e19;
}

.side-nav-menu-text-sm {
    color: white;
    font-size: 20px;
    line-height: 1.4em;
    font-weight: 700;
    display: none;
}

.side-nav-submenu-wrapper{
    margin:0;
    margin-top: 15px;
    margin-left: 10px;
    padding: 0px;
    list-style: none;
}



.side-nav-subment-text{
    color: white;
    font-weight: 300;
    font-size: 15px;
    margin-bottom: 5px
}

.social-media-holder{
    padding-left: inherit;
    list-style: none;
    display: inline-flex;
    position: absolute; 
    bottom: 0
}

.social-media-holder li{
    font-size: 32px;
    color: white;
    margin-right: 20px; 
}

.social-media-holder li a:hover{
    color: #ff6e19;
}



@media screen and (max-width: 1080px) {
    .menu-item {
        display: none !important;
    }
    .menu-item-dropdown{
        display: none !important;
    }
    .side-nav-menu-text-sm {
        display: block !important;
    }
}

@media screen and (max-width: 600px) {
    .side-nav-wrapper{
        width: 100% !important;
    }

    .nav-wrapper{
        padding-left: 15px!important;
        padding-right: 15px!important;
        padding-top: 5px;
        padding-bottom:5px;
        display: flex;
        flex-direction: row!important;
    }

    .menu-item-icon{
        margin: 0px;
        display: block!important;
    }

    #map{
        height:400px!important;
    }

    .two-columns #map{
        flex: inherit!important;
    }

    .logo-holder{
        left: -20px;
        top: -60px;
    }

    .logo-holder img{
        height: 75%!important;
    }

    .logo-footer{
        width:100%!important;
    }

    .logo-footer .logo-holder{
        left: 0!important;
    }

    .caption{
        font-size: 20px;
        margin-left: -12px;
    }

    .wpb_wrapper h1{
        font-size: 55px!important;
    }

    .action-block{
        padding:0px 20px 0px 20px!important;
    }

    .d-flex-row{
        flex-direction: column!important;
    }

    .d-flex-row.testimonial-slider{
        flex-direction: row!important;
        align-items: center!important;
    }

    .control{
        display:none!important;
    }

    .testimonial-title{
        margin: 0px 0px 0px 0px!important;
    }
    .testimonial-author{
        margin-top:10px!important;
    }

    .about-content-padding, .hompage-contact-info, .contact-form{
        padding: 30px!important;
    }

    .footer-menu-list-wrapper{
        display:flex!important;
        flex-direction: column!important;
        padding-left: 0!important;
        align-items: center!important;
    }
    .footer-copy{
        flex-direction: column!important;
        align-items: center;
    }

  
}

.affiliation-box {
    position: absolute;
    bottom: 5px;
    right: 20px;
    font-size: 10px;
    font-weight: bold;
    font-family: OpenSans;
}

@media screen and (max-width: 600px) {
    .affiliation-box {
        bottom: 10px;
        font-size: 8px;
        right: 20px;
    }
}
