@font-face {
  font-family: PoppinsRegular;
  src: url(./assets/fonts/poppins/Poppins-Regular.otf);
}

@font-face {
  font-family: PoppinsBold;
  src: url(./assets/fonts/poppins/Poppins-Bold.otf);
}

@font-face {
  font-family: PoppinsSemiBold;
  src: url(./assets/fonts/poppins/Poppins-SemiBold.otf);
}

@font-face {
  font-family: PoppinsItalic;
  src: url(./assets/fonts/poppins/Poppins-Italic.otf);
}

@font-face {
  font-family: PoppinsBoldItalic;
  src: url(./assets/fonts/poppins/Poppins-BoldItalic.otf);
}

@font-face {
  font-family: PoppinsBlackItalic;
  src: url(./assets/fonts/poppins/Poppins-BlackItalic.otf);
}

@font-face {
  font-family: PoppinsBlack;
  src: url(./assets/fonts/poppins/Poppins-Black.otf);
}

@font-face {
  font-family: PoppinsThin;
  src: url(./assets/fonts/poppins/Poppins-Thin.otf);
}

@font-face {
  font-family: PoppinsLight;
  src: url(./assets/fonts/poppins/Poppins-Light.otf);
}

@font-face {
  font-family: OpenSans;
  src: url(./assets/fonts/open-sans/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansLight;
  src: url(./assets/fonts/open-sans/OpenSans-Light.ttf);
}

body {
  margin: 0;
  font-family: OpenSans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 20px 0;
  font-family: PoppinsBold, sans-serif;
  color: #252525;
  font-weight: 700;
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 45px;
}

h3 {
  font-size: 28px;
  line-height: 1.21em;
}

h1,
h2 {
  line-height: 1.2em;
}

h4 {
  line-height: 1.4rem;
}

a {
  text-decoration: none;
}

body {
  overflow-x: hidden;
}

a,
body,
p a {
  color: #444;
  font-family: OpenSansLight;
}

.btn-simple-link {
  position: relative;
  width: auto;
  margin: 0;
  font-family: Poppins, sans-serif;
  font-size: 17px;
  line-height: 2em;
  font-weight: 600;
  box-sizing: border-box;
  border-radius: 0;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  padding: 15px 43px;
  cursor: pointer;
  text-decoration: none;
}

.btn-outline {
  border: 1px solid;
}

.d-flex {
  display: flex;
}

.d-flex-column {
  flex-direction: column;
}

.d-flex-row {
  flex-direction: row;
}

.row-reverse {
  flex-direction: row-reverse;
}

.full-width {
  flex: 1;
  width: 100%;
}

.full-height {
  flex: 1;
}

.align-center {
  align-items: center;
}

.flex1 {
  flex: 1 !important;
}

.grid-margin {
  margin-top: 30px;
}

.grid-container-3 {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: 25px;
}

.content {
  margin-top: 150px;
}
.grid-container-smc {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 25px;
}

.attachment-full {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.justify-center {
  justify-content: center;
}

.bg-red {
  background-color: #ff6e19 !important;
}

.two-columns .column {
  flex: 0.5;
}

.two-columns .column-3 {
  flex: 0.6;
}

.two-columns .column-4 {
  flex: 1;
}

.three-columns .column {
  flex: 0.33;
}

.two-columns .column-1 {
  flex: 0.4;
}

.content-padding {
  padding: 0 10% 25px 14% !important;
}

.two-rows .row {
  flex: 0.5;
}

.column-holder {
  vertical-align: middle;
  height: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
}

.home-bg {
  background-image: url(https://res.cloudinary.com/dvuq9n454/image/upload/v1639252879/66fd3cad-3c8b-408d-b8e4-542842f5e3ad_izagx5.jpg);
}

.space-between {
  justify-content: space-between;
}

.action-block {
  position: absolute;
  bottom: 30px;
  padding: 0px 40px 0px 40px;
}

.content-block h2 {
  margin-bottom: 0px !important;
}

.homepage-about-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.about-content-padding {
  padding: 7px 30px 0px 62px !important;
}

.h-100 {
  height: 100%;
}

.margin-1 {
  margin-top: -10px;
}

.heading-title {
  margin: 0;
  line-height: 1.2em;
}

.sub-para {
  display: block;
  margin: 14px 0 0;
  font-size: 19px;
  font-family: PoppinsLight !important;
}

.about-content-para {
  margin: 10px 0;
}

.btn-simple {
  padding: 0 !important;
  color: #444;
  background-color: transparent;
  border: 0;
  vertical-align: middle;
  font-weight: 500;
}

.play-btn {
  position: absolute;
  top: 50%;
  left: 40%;
  display: block;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.play-btn img {
  width: inherit;
}

.underline-link {
  display: inline-block;
  vertical-align: middle;
}

.underline-link::after {
  content: "";
  display: block;
  border-bottom: 1px solid;
  border-color: inherit;
  position: absolute;
  bottom: -5px;
  right: 0;
  width: 100%;
}

.homepage-contact-bg {
  background-color: #252525;
}

.hompage-contact-info {
  padding: 53px 0px 0px 57px !important;
}

.title-line {
  border-color: #ffffff;
  width: 38px;
  border-bottom-width: 3px;
  margin-top: 23px;
  margin-bottom: 9px;
  border-bottom: 1px solid #ebebeb;
  margin: 10px 0;
}

.homepage-contact-form-bg {
  background-image: url(https://academist.qodeinteractive.com/wp-content/uploads/2018/07/boxed-home-img-6.jpg);
  background-size: cover;
}

.contact-form {
  padding: 8px 65px 0 65px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.contact-form .admission-button {
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 10px 25px;
  text-align: center;
}

.contact-icon-holder {
  position: relative;
  display: table;
  table-layout: fixed;
  height: auto;
  width: 100%;
  margin-bottom: 8px;
}

.contact-icon-info {
  color: #ffffff;
  padding-left: 25px !important;
  width: 99%;
  padding: 0 0 0 13px;
  box-sizing: border-box;
  font-weight: 400;
  font-family: Poppins, sans-serif;
  margin-top: -3px;
}

.empty-space {
  height: 30px;
}

p {
  margin: 10px 0;
}

.icon-holder {
  position: relative;
  display: table-cell;
  vertical-align: top;
  width: 1%;
}

.icon {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 17px;
  line-height: inherit;
  fill: white;
}

.icon-primary {
  fill: #ff6e19;
}

.icon-primary-feather {
  stroke: #ff6e19 !important;
}

.text-primary {
  color: #ff6e19 !important;
}

.text-bold {
  font-style: bold;
  font-family: PoppinsRegular;
}

.text-center {
  text-align: center;
}

.icon path {
  stroke: white !important;
}

.icon-primary path {
  stroke: #ff6e19 !important;
}

.btn {
  position: relative;
  width: auto;
  margin: 0;
  font-family: Poppins, sans-serif;
  font-size: 17px;
  line-height: 2em;
  font-weight: 600;
  box-sizing: border-box;
  border-radius: 0;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  padding: 15px 43px;
  cursor: pointer;
}

.container {
  margin: 0 auto;
  position: relative;
  width: 1300px;
  padding-top: 75px;
}

.btn-primary {
  background-color: #ff6e19;
  color: white;
  justify-content: center;
  align-items: center;
  border: none;
}

.swiper-button-prev,
.swiper-button-next {
  color: #ff6e19 !important;
}

.font-bold{
  font-weight: bold;
}

.info-block {
  width: 100%;
  text-align: center;
  min-height: 120px !important;
}

.info-block-sm {
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  padding: 20px;
  box-shadow: 4px 4px #00000005;
}

.year-est {
  font-size: 90px;
}

.year-founder{
  font-size: 60px;
}

.school-table table {
  width: 60%;
  text-align: left;
  margin: auto;
  min-width: 340px;
}

.class-table td, .class-table th{
  padding: 15px!important;
  background: none!important;
}

.class-table td, .class-table td p, .class-table td p span{
  font-weight: bold;
}

.school-table table td{
  padding: 10px 0;
  border: none!important;
}

.school-table table .heading{
  font-weight: bold;
}

.ft-32 {
  font-size: 32px !important;
}

.info-block .block-content-padding {
  padding: 40px !important;
}

.info-block .block-content-padding p {
  font-size: 32px !important;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 100%;
  }
  .content-wrapper {
    overflow-x: hidden;
    padding-left: 15px;
    padding-right: 15px;
  }
  .content {
    margin-top: 70px;
  }
  .school-info-holder{
    display: flex;
    flex-direction: column;
  }
  .info-block .block-content-padding p{
    line-height: 32px;
  }
  .year-founder{
    font-size: 40px;
  }
}
